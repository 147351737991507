export const TYPES = {
    INewsRepository: Symbol.for("INewsRepository"),
    NewsService: Symbol.for("NewsService"),
    IBlockchainRepository: Symbol.for("IBlockchainRepository"),
    BlockchainService: Symbol.for("BlockchainService"),    
    IWalletRepository: Symbol.for("IWalletRepository"),
    WalletService: Symbol.for("WalletService"),
    ITopicRepository: Symbol.for("ITopicRepository"),
    TopicService: Symbol.for("TopicService"),
    IVoteRepository: Symbol.for("IVoteRepository"),
    VoteService: Symbol.for("VoteService"),
    IEthRepository: Symbol.for("IEthRepository"),
    IDaiRepository: Symbol.for("IDaiRepository"),
    IGazzethRepository: Symbol.for("IGazzethRepository"),
}
